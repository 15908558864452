import { Box, Typography, List } from "@mui/material";
import { useFinalStepContext } from "../../../../../../../../context/FinalStepContext";
import ListItem from "../ListItem";

const FoundProductsList: React.FC = () => {
  const finalStepContext = useFinalStepContext();

  return (
    <Box className={"found-product-div"}>
      <Typography variant="h6" gutterBottom>
        Here are some similar products:
      </Typography>
      <List className="list">
        {finalStepContext.DataMapProd.map((product, index) => {
          return <ListItem product={product} key={index} />;
        })}
      </List>
    </Box>
  );
};

export default FoundProductsList;
