import { Box, Typography, IconButton } from "@mui/material";
import ManualSKUInput from "../../../../../ManualSKUInput";
import ReplayIcon from "@mui/icons-material/Replay";
import { useProductContext } from "../../../../../../context/ProductContext";
import { useFinalStepContext } from "../../../../../../context/FinalStepContext";
import { useEffect } from "react";
import {
  fetchProduct,
  fetchProductPrice,
} from "../../../../../../helpers/fetchProductId";
import { Product } from "../../../../../../shared/types";
import FoundProductsList from "./components/FoundProductsList";

const ErrorSection: React.FC = () => {
  const productContext = useProductContext();
  const finalStepContext = useFinalStepContext();

  useEffect(() => {
    if (finalStepContext.DataMapSku.length > 0) {
      finalStepContext.DataMapSku.forEach((sku) => {
        fetchProduct(sku).then((product: Product) => {
          if (!product) {
            return;
          }
          fetchProductPrice(sku).then((price: string) => {
            product.price = price;
            finalStepContext.setDataMapProd((DataMapProd) => [
              ...DataMapProd,
              product,
            ]);
            finalStepContext.unsetLoading();
            finalStepContext.setButtonDisplay(true);
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalStepContext.DataMapSku]);

  const GoBackBtn = () => {
    return (
      <Box onClick={finalStepContext.handleGoBack} className={"go-back-div"}>
        <IconButton>
          <ReplayIcon />
        </IconButton>
      </Box>
    );
  };

  const TopHeaders = () => {
    return (
      <Box>
        {productContext.productPhotoInvalid && (
          <Typography variant="h4" m={"auto"} mb={2}>
            No match
          </Typography>
        )}
        <Typography variant="h5" gutterBottom>
          Find product by SKU number
        </Typography>
        <Typography variant="h6" gutterBottom>
          Please input the SKU number in the search bar to find the desired
          product:
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={"section-error"}>
      <TopHeaders />
      <Box className={"additional-msg-div"}>
        <Typography variant="body1" gutterBottom>
          {finalStepContext.additionalErrorMessage}
        </Typography>
      </Box>
      {finalStepContext.DataMapProd.length > 0 && <FoundProductsList />}
      <Box>
        <ManualSKUInput />
      </Box>
      <GoBackBtn />
    </Box>
  );
};

export default ErrorSection;
