import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAdminContext } from "../../../../../../context/AdminContext";

const SignInForm = () => {
  const adminContext = useAdminContext();

  const handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username")?.toString() || "";
    const password = data.get("password")?.toString() || "";
    if (username.length <= 0 || password.length <= 0) {
      adminContext.setSigninMessage("Please fill all required fields!");
      return;
    }
    adminContext.setUsername(username);
    adminContext.setPassword(password);
    adminContext.setSignedIn(true);
  };

  return (
    <>
      {adminContext.signedIn ? (
        <Box>
          <Typography component="h3" variant="h6">
            Welcome, {adminContext.username}! You are signed in!
          </Typography>
          <Box className={"signed-in"} m={2}>
            <Button
              sx={{ mb: 2 }}
              variant="outlined"
              onClick={() => {
                adminContext.setSignedIn(false);
                ReactGA.event({
                  category: "user",
                  action: "click",
                  label: "signout",
                });
              }}
            >
              Sign out
            </Button>
            <Link to="/admin" className="admin-panel-link">
              Admin panel
            </Link>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography component="h1" variant="h5">
            {adminContext.signinMessage}
          </Typography>
          <Box
            component="form"
            id="sign-in-form"
            onSubmit={handleSignIn}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" className={"check"} />}
              label="Remember me"
            />
            <Button
              type="submit"
              id="submit-btn"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Grid item>
                <MuiLink
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    adminContext.setSignupMessage("Sign up");
                    adminContext.setSelectedForm(false);
                    ReactGA.event({
                      category: "loginpanel",
                      action: "click",
                      label: "signup",
                    });
                  }}
                  variant="body2"
                >
                  Don't have an account?
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SignInForm;
