import { LoadingButton } from "@mui/lab";
import { Box, Button, CardActions, Typography } from "@mui/material";
import { useStepContext } from "../../../../../../context/StepContext";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  fetchProduct,
  fetchProductId,
  fetchProductPrice,
} from "../../../../../../helpers/fetchProductId";
import ReactGA from "react-ga4";
import { Product } from "../../../../../../shared/types";
import { ChangeEvent } from "react";
import { useProductContext } from "../../../../../../context/ProductContext";
import InputIcon from "@mui/icons-material/Input";
import { useFinalStepContext } from "../../../../../../context/FinalStepContext";

const CTAButtons = () => {
  const stepContext = useStepContext();
  const productContext = useProductContext();
  const finalStepContext = useFinalStepContext();

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        if (fileReader.result === "" || !fileReader.result) {
          return;
        }

        const base64Result = fileReader.result.toString().split(",")[1];
        resolve(base64Result);

        fetchProductId(base64Result).then((pid: string) => {
          fetchProduct(pid).then((product: Product) => {
            if (!product) {
              finalStepContext.setDataMapProd([]);
              finalStepContext.setDataMapSku([]);

              ReactGA.event({
                category: "resultpage",
                action: "unsuccesfulupload",
                label: pid,
              });
              productContext.setProductPhotoInvalid(true);
              stepContext.handleError();
              stepContext.stopLoading();
              stepContext.displayStepTwo();
              return;
            }

            fetchProductPrice(pid).then((price: string) => {
              finalStepContext.setDataMapProd([]);
              finalStepContext.setDataMapSku([]);
              productContext.setProduct(product);
              productContext.setProductPrice(price);
              ReactGA.event({
                category: "homepage",
                action: "takeuploadphoto",
                label: pid,
              });
              stepContext.handleSuccess();
              stepContext.stopLoading();
              stepContext.displayStepTwo();
            });
          });
        });
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.error("Select a file");
      return;
    }
    const { files } = event.target;
    convertBase64(files[0]);
    stepContext.setLoading();
  };

  return (
    <CardActions className="card-actions">
      {stepContext.loadingButtonDisplay && (
        <LoadingButton
          disabled={true}
          className={"loading-btn"}
          loading={true}
        ></LoadingButton>
      )}
      {stepContext.buttonDisplay && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              endIcon={<FileUploadIcon />}
              className={"submit-btn"}
              variant={"contained"}
              size="medium"
              component={"label"}
              sx={{
                marginBottom: "15px !important",
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => handleFileUpload(e)}
              />
              Take or upload a photo
            </Button>
            <Typography
              variant="caption"
              fontWeight={"bold"}
              color="#fff"
              sx={{
                justifyContent: "center",
              }}
            >
              Choose a clear photo snapped with natural lighting
            </Typography>
          </Box>
          <Button
            endIcon={<InputIcon />}
            className={"submit-btn"}
            variant={"contained"}
            size="medium"
            component={"label"}
            onClick={() => {
              finalStepContext.setDataMapProd([]);
              finalStepContext.setDataMapSku([]);
              stepContext.handleError();
              stepContext.displayStepTwo();
              stepContext.setButtonDisplay(true);
              ReactGA.event({
                category: "stepone",
                action: "click",
                label: "manualinput",
              });
            }}
          >
            Enter a SKU number manually
          </Button>
        </>
      )}
    </CardActions>
  );
};

export default CTAButtons;
