import { Box, Typography, Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { useProductContext } from "../../../../../../../../context/ProductContext";
import { useFinalStepContext } from "../../../../../../../../context/FinalStepContext";

const MainProd: React.FC = () => {
  const productContext = useProductContext();
  const finalStepContext = useFinalStepContext();

  return (
    <>
      <Box className={"prod-details"}>
        <Typography variant="h6" gutterBottom>
          <strong>Category: </strong>
          {productContext.product.mainCategory}
        </Typography>
        <Typography variant="h6" gutterBottom>
          <strong>Brand: </strong>
          {productContext.product.brand}
        </Typography>
        <Typography variant="h6" gutterBottom>
          <strong>SKU: </strong>
          {productContext.product.sku}
        </Typography>
        <Typography variant="h6" gutterBottom>
          <strong>Price: </strong>$
          {parseFloat(productContext.productPrice).toFixed(2)} USD
        </Typography>
        <Box className={"cta-div"}>
          <Button
            endIcon={<OpenInBrowserIcon />}
            className={"view-prod-btn"}
            variant={"contained"}
            size="medium"
            component={"label"}
            onClick={finalStepContext.handleProductAnchor}
          >
            View product
          </Button>
          <Button
            endIcon={<ReplayIcon />}
            className={"go-back-btn"}
            variant={"contained"}
            size="medium"
            component={"label"}
            onClick={finalStepContext.handleGoBack}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Box className={"product-div"}>
        <Box className={"content"}>
          <img
            src={
              productContext.formatImageLink(
                productContext.product.imageLink
              ) || ""
            }
            alt={"product-img"}
            className={"product-img"}
          />
          <Box className={"product-desc-div"}>
            <Typography variant="body1" className={"product-desc"} gutterBottom>
              {productContext.product.nameLongDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MainProd;
