import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useStepContext } from "../../../../context/StepContext";
import ErrorSection from "./components/ErrorSection";
import SuccessSection from "./components/SuccessSection";

const StepTwo = () => {
  const stepContext = useStepContext();
  return (
    <Box className={"step-two"}>
      <Card className={`card ${!stepContext.isSuccess ? "error" : ""}`}>
        <CardContent className={"card-content"}>
          {stepContext.isSuccess ? <SuccessSection /> : <ErrorSection />}
        </CardContent>
      </Card>
    </Box>
  );
};

export default StepTwo;
