import { Box, Typography, Button } from "@mui/material";
import { useProductContext } from "../../../../../../../../context/ProductContext";
import { useStepContext } from "../../../../../../../../context/StepContext";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import ReactGA from "react-ga4";
import { Product } from "../../../../../../../../shared/types";
import { useFinalStepContext } from "../../../../../../../../context/FinalStepContext";

type ListItemProps = {
  product: Product;
};

const ListItem = ({ product }: ListItemProps): JSX.Element => {
  const stepContext = useStepContext();
  const productContext = useProductContext();
  const finalStepContext = useFinalStepContext();

  return (
    <Box>
      <Box className={"found-product"}>
        <img
          src={productContext.formatImageLink(product.imageLink) || ""}
          alt="found-product-img"
          className="found-product-img"
        />
        <Box m={1}>
          <Typography variant="body1" className="found-product-title">
            {product.sku}
          </Typography>
          <Typography variant="body2" className="found-product-title">
            {product.nameShortDescription}
          </Typography>
        </Box>
        <Button
          endIcon={<OpenInBrowserIcon />}
          className={"view-prod-btn"}
          variant={"contained"}
          size="medium"
          component={"label"}
          onClick={() => {
            finalStepContext.setDataMapProd([]);
            finalStepContext.setDataMapSku([]);
            productContext.setProduct(product);
            productContext.setProductPrice(product.price);
            finalStepContext.setAdditionalErrorMessage("");
            ReactGA.event({
              category: "manualinputsection",
              action: "viewproduct",
              label: product.sku,
            });
            stepContext.handleSuccess();
            stepContext.displayStepTwo();
          }}
        >
          View product
        </Button>
      </Box>
    </Box>
  );
};

export default ListItem;
