import { Box } from "@mui/material";
import { useStepContext } from "../../../../../../context/StepContext";

const Footer = () => {
  const stepContext = useStepContext();

  return (
    <Box className={"step-footer"}>
      <p className={"sku-availability-msg"}>
        This is a new service. Please check{" "}
        <a
          className="anchor-element"
          href={"./assets/files/sku_list.pdf"}
          target={"_blank"}
          rel="noreferrer"
        >
          here
        </a>{" "}
        for the current SKU coverage available
      </p>
      {stepContext.buttonDisplay && (
        <a
          className="help"
          target={"_blank"}
          href="https://www.se.com/us/en/work/support/"
          rel="noreferrer"
        >
          Not what you are looking for? Contact our support team to receive
          technical support or additional assistance.
        </a>
      )}
    </Box>
  );
};

export default Footer;
