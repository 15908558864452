import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import AdminPanel from "./components/AdminPanel";
import { Box } from "@mui/material";
import LoginPanel from "./components/LoginPanel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useStepContext } from "../../context/StepContext";

const Steps = () => {
  const stepContext = useStepContext();

  return (
    <Box className={"steps"}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPanel />} />
          <Route path="/admin" element={<AdminPanel />} />
          {stepContext.stepOneDisplay && (
            <Route path="/" element={<StepOne />} />
          )}
          {stepContext.stepTwoDisplay && (
            <Route path="/" element={<StepTwo />} />
          )}
        </Routes>
      </BrowserRouter>
    </Box>
  );
};

export default Steps;
