import "./App.css";
import "./styles/custom.scss";
import CssBaseline from "@mui/material/CssBaseline";
import SchneiderLogo from "./components/SchneiderLogo/index";
import Copyright from "./components/Copyright";
import Steps from "./components/Steps";
import { Box } from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  AdminContextProvider,
  StepContextProvider,
  ProductContextProvider,
  FinalStepContextProvider,
} from "./context";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-C1F4HHVZQT");
  }, []);

  return (
    <AdminContextProvider>
      <StepContextProvider>
        <ProductContextProvider>
          <FinalStepContextProvider>
            <main className="App">
              <CssBaseline />
              <Box component="main">
                <SchneiderLogo />
                <Steps />
                <Copyright />
              </Box>
            </main>
          </FinalStepContextProvider>
        </ProductContextProvider>
      </StepContextProvider>
    </AdminContextProvider>
  );
}
export default App;
