import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const SchneiderLogo = () => {
  let imageSrc = "./assets/images/se_logo.svg";

  return (
    <Box
      className={"nav-component"}
      sx={{ position: "relative", width: "100%" }}
    >
      <Box
        className={"se-logo-div"}
        sx={{ display: "flex", margin: "5px auto 0", justifyContent: "center" }}
      >
        <Link color="inherit" target={"_blank"} href="https://www.se.com/">
          <img
            className="se-logo"
            src={imageSrc}
            alt={"Schneider electric logo"}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default SchneiderLogo;
