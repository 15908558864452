import React, { useState } from "react";
import { Props, ProductContextType } from "../types";
import { Product } from "../../shared/types";

const ProductContext = React.createContext<null | ProductContextType>(null);

export const ProductContextProvider = ({ children }: Props) => {
  const [isSuccess, setSuccess] = useState(false);
  const [productPhotoInvalid, setProductPhotoInvalid] = useState(false);
  const [productPrice, setProductPrice] = useState("");
  const productInitValues = {
    brand: "",
    imageLink: "",
    imageType: "",
    invoiceDescription: "",
    mainCategory: "",
    nameLongDescription: "",
    nameShortDescription: "",
    sku: "",
    subCategory1: "",
    subCategory2: "",
    tier: "",
    price: productPrice,
  };
  const [product, setProduct] = useState<Product>(productInitValues);

  function formatImageLink(imgLink: string) {
    let formattedLink: string = "";
    if (imgLink === "" || imgLink === undefined) {
      return;
    }
    formattedLink = imgLink.replace(
      "/assets/magic360/viewer.html?initial=",
      ""
    );

    formattedLink = formattedLink.split("&")[0];
    return formattedLink;
  }

  const ProductContextValues = {
    product,
    setProduct,
    productPrice,
    setProductPrice,
    isSuccess,
    setSuccess,
    productPhotoInvalid,
    setProductPhotoInvalid,
    formatImageLink,
  };

  return (
    <ProductContext.Provider value={ProductContextValues}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  const productContext = React.useContext(ProductContext);

  if (!productContext)
    throw new Error("You need to use this hook inside a context provider");

  return productContext;
};
