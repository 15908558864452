import React, { useState } from "react";
import { AdminContextType, Props } from "../types";

const AdminContext = React.createContext<null | AdminContextType>(null);

export const AdminContextProvider = ({ children }: Props) => {
  const [signedIn, setSignedIn] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [signinMessage, setSigninMessage] = useState("Sign in");
  const [signupMessage, setSignupMessage] = useState("Sign up");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const AdminContextValues = {
    signedIn,
    setSignedIn,
    selectedForm,
    setSelectedForm,
    setUsername,
    setPassword,
    username,
    password,
    signinMessage,
    setSigninMessage,
    signupMessage,
    setSignupMessage,
    loading,
    setLoading,
    message,
    setMessage,
  };

  return (
    <AdminContext.Provider value={AdminContextValues}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const adminContext = React.useContext(AdminContext);

  if (!adminContext)
    throw new Error("You need to use this hook inside a context provider");

  return adminContext;
};
