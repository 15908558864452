import axios from "axios";

const fetchProductId = async function (base64: string) {
  try {
    const { data } = await axios.post(
      "https://pixie-ai.sesmartconfig.com/nextuser/sku_detection",
      { text: base64 },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          withCredentials: false,
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
        },
      }
    );

    return data.detected_sku || data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const fetchSimilarProductSkus = async function (sku: string) {
  try {
    const { data } = await axios.post(
      "https://pixie-ai.sesmartconfig.com/nextuser/sku_similar",
      { text: sku },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          withCredentials: false,
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
        },
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const fetchProduct = async function (productId: string) {
  try {
    const { data } = await axios.get(
      "https://pixie-api.sesmartconfig.com/product-info?sku=" + productId
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const fetchProductPrice = async function (productId: string) {
  try {
    const { data } = await axios.get(
      "https://pixie-api.sesmartconfig.com/active-product-info?sku=" + productId
    );
    return data.priceList;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const fetchProductUrl = async function (
  productId: string,
  distributorCode?: string
) {
  try {
    let endpointUrl =
      "https://pixie-api.sesmartconfig.com/product/" + productId;
    if (!!distributorCode) {
      endpointUrl += "?distributorCode=" + distributorCode;
    }
    const { data } = await axios.get(endpointUrl);

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

export {
  fetchProductId,
  fetchProduct,
  fetchProductUrl,
  fetchProductPrice,
  fetchSimilarProductSkus,
};
