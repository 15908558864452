import axios from "axios";

const fetchUser = async function () {
  try {
    const { data } = await axios.get(
      "https://pixie-api.sesmartconfig.com/users"
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const userSignup = async function (
  username: string,
  password: string,
  distCode?: string
) {
  try {
    const { data } = await axios.post(
      "https://pixie-api.sesmartconfig.com/users/signup",
      {
        username: username,
        password: password,
        distributorCode: distCode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          withCredentials: false,
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
        },
      }
    );

    return data.message;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

export { fetchUser, userSignup };
