import { Box, Typography } from "@mui/material";
import ManualSKUInput from "../../../../../ManualSKUInput";
import { useFinalStepContext } from "../../../../../../context/FinalStepContext";
import MainProd from "./components/MainProd";

const SuccessSection: React.FC = () => {
  const finalStepContext = useFinalStepContext();

  return (
    <Box className={"section-success"}>
      <Box>
        <Typography variant="h5" gutterBottom m={0}>
          <strong>Product match found!</strong>
        </Typography>
      </Box>
      <Box className={"main-prod-div"}>
        <MainProd />
      </Box>
      <Box className={"custom-message"}>
        <Box className={"instruction"}>
          <Typography
            m={"auto"}
            mb={1}
            mt={0}
            sx={{ textAlign: "center" }}
            variant="body2"
            gutterBottom
          >
            This is not the product you are looking for?
            <br />
            Input SKU number manually!
            <br />
          </Typography>
          <Typography className={"error-msg"} variant="body2" gutterBottom>
            {finalStepContext.additionalErrorMessage}
          </Typography>
        </Box>
        <ManualSKUInput />
      </Box>
    </Box>
  );
};

export default SuccessSection;
