import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";

const NotSignedInSection = () => {
  return (
    <>
      <Box className="title-div">
        <Typography variant="h5" color="text.primary">
          You are not signed in
        </Typography>
        <Typography variant="h6" color="text.primary">
          Please go to sign in page and authorize your credentials!
        </Typography>
      </Box>
      <Card className={"card"}>
        <CardContent className={"card-content"}>
          <CardActions className="card-actions">
            <Link
              className={"submit-btn"}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              to="/login"
            >
              <span>Sign in page</span>
            </Link>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
};

export default NotSignedInSection;
