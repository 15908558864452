import axios from "axios";
import { Buffer } from "buffer";

type authObjectProps = {
  username: string;
  password: string;
};

const processCsv = async function (authObj: authObjectProps, csvFile: any) {
  try {
    const token = Buffer.from(
      authObj.username + ":" + authObj.password
    ).toString("base64");
    const encodedToken = token;
    let file = csvFile.files[0];
    const { data } = await axios.post(
      "https://pixie-api.sesmartconfig.com/mapping/process-csv",
      { file: file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json",
          Authorization: "Basic " + encodedToken,
          withCredentials: true,
        },
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

const templateUrl = async function (authObj: authObjectProps, url: string) {
  try {
    const token = Buffer.from(
      authObj.username + ":" + authObj.password
    ).toString("base64");
    const encodedToken = token;
    const { data } = await axios.post(
      "https://pixie-api.sesmartconfig.com/mapping/template-url",
      { templateUrl: url },
      {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Basic " + encodedToken,
          withCredentials: true,
        },
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      return "An unexpected error occurred";
    }
  }
};

export { processCsv, templateUrl };
