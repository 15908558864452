import React, { useState } from "react";
import { Props, StepContextType } from "../types";

const StepContext = React.createContext<null | StepContextType>(null);

export const StepContextProvider = ({ children }: Props) => {
  const [stepOneDisplay, setStepOneDisplay] = useState(true);
  const [stepTwoDisplay, setStepTwoDisplay] = useState(false);

  const [buttonDisplay, setButtonDisplay] = useState(true);
  const [loadingButtonDisplay, setLoadingButtonDisplay] = useState(false);

  const [isSuccess, setSuccessState] = useState(false);

  const setLoading = () => {
    setLoadingButtonDisplay(true);
    setButtonDisplay(false);
  };

  const stopLoading = () => {
    setLoadingButtonDisplay(false);
    setButtonDisplay(true);
  };

  const displayStepOne = () => {
    setStepOneDisplay(true);
    setStepTwoDisplay(false);
  };

  const displayStepTwo = () => {
    setStepOneDisplay(false);
    setStepTwoDisplay(true);
  };

  const handleSuccess = () => {
    setSuccessState(true);
  };

  const handleError = () => {
    setSuccessState(false);
  };

  const StepContextValues = {
    stepOneDisplay,
    setStepOneDisplay,
    stepTwoDisplay,
    setStepTwoDisplay,
    buttonDisplay,
    setButtonDisplay,
    loadingButtonDisplay,
    setLoadingButtonDisplay,
    isSuccess,
    handleSuccess,
    handleError,
    setLoading,
    stopLoading,
    displayStepOne,
    displayStepTwo,
  };

  return (
    <StepContext.Provider value={StepContextValues}>
      {children}
    </StepContext.Provider>
  );
};

export const useStepContext = () => {
  const stepContext = React.useContext(StepContext);

  if (!stepContext)
    throw new Error("You need to use this hook inside a context provider");

  return stepContext;
};
