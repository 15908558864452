import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import { useFinalStepContext } from "../../context/FinalStepContext";

const ManualSKUInput = () => {
  const finalStepContext = useFinalStepContext();

  return (
    <Container component="form" className={"manual-input-form inline"}>
      <Box className={"input-div"}>
        {!finalStepContext.buttonLoading && (
          <TextField
            id="filled-basic"
            label="SKU number"
            variant="outlined"
            size="medium"
            color="primary"
            className="input-field"
          />
        )}
        <Box className="form-content">
          {finalStepContext.loadingButtonDisplay && (
            <LoadingButton
              loading={finalStepContext.buttonLoading}
              className="loading-btn"
            ></LoadingButton>
          )}
          {finalStepContext.buttonDisplay && (
            <IconButton
              size="medium"
              type={"button"}
              onClick={finalStepContext.handleSubmit}
              className="search-btn"
            >
              <SearchIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ManualSKUInput;
