import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactGA from "react-ga4";
import { useAdminContext } from "../../../../../../context/AdminContext";
import { userSignup } from "../../../../../../helpers/fetchUser";

const SignUpForm = () => {
  const adminContext = useAdminContext();

  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username")?.toString() || "";
    const password = data.get("password")?.toString() || "";
    const distributorCode = data.get("distributor-code")?.toString() || "";
    if (
      username.length <= 0 ||
      password.length <= 0 ||
      distributorCode.length <= 0
    ) {
      adminContext.setSignupMessage("Please fill all required fields!");
      return;
    }

    userSignup(username, password, distributorCode).then((data: any) => {
      if (!data) {
        adminContext.setSignupMessage("Username already exists!");
        return;
      }
      adminContext.setSignupMessage(data);
    });
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {adminContext.signupMessage}
      </Typography>
      <Box
        component="form"
        id="sign-up-form"
        onSubmit={handleSignUp}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="distributor-code"
          label="Distributor code"
          type="text"
          id="distributor-code"
        />
        <Button
          type="submit"
          id="submit-btn"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          <Grid item>
            <MuiLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                adminContext.setSignupMessage("Sign up");
                adminContext.setSelectedForm(true);
                ReactGA.event({
                  category: "signupform",
                  action: "click",
                  label: "signin",
                });
              }}
              variant="body2"
            >
              Already have an account?
            </MuiLink>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignUpForm;
