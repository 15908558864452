import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Footer from "./components/Footer";
import CTAButtons from "./components/CTAButtons";

const StepOne = () => {
  return (
    <Box className={"step-one"}>
      <Box className="title-div">
        <Typography variant="h5" color="text.primary">
          Product Recognition Tool
        </Typography>
      </Box>
      <Card className={"card"}>
        <CardContent className={"card-content"}>
          <Box className="desc-box">
            <Typography variant={"h5"} className={"desc"} gutterBottom>
              Tap one of the buttons below and find your product
            </Typography>
          </Box>
          <CTAButtons />
          <Footer />
        </CardContent>
      </Card>
    </Box>
  );
};

export default StepOne;
