import Box from "@mui/material/Box";
import { useAdminContext } from "../../../../context/AdminContext";
import NotSignedInSection from "./NotSignedInSection";
import SignedInSection from "./SignedInSection";

const AdminPanel = () => {
  const adminContext = useAdminContext();

  return (
    <Box className={"panel"}>
      <Box className={"admin-panel"}>
        {adminContext.signedIn ? <SignedInSection /> : <NotSignedInSection />}
      </Box>
    </Box>
  );
};

export default AdminPanel;
