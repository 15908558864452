import React, { useState } from "react";
import { Props, FinalStepContextType } from "../types";
import { useStepContext } from "../StepContext";
import { useProductContext } from "../ProductContext";
import ReactGA from "react-ga4";
import {
  fetchProduct,
  fetchSimilarProductSkus,
  fetchProductPrice,
} from "../../helpers/fetchProductId";
import { Product } from "../../shared/types";

const FinalStepContext = React.createContext<null | FinalStepContextType>(null);

export const FinalStepContextProvider = ({ children }: Props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState(true);
  const [additionalErrorMessage, setAdditionalErrorMessage] = useState("");
  const [loadingButtonDisplay, setLoadingButtonDisplay] = useState(false);

  const [DataMapSku, setDataMapSku] = useState<string[]>([]);
  const [DataMapProd, setDataMapProd] = useState<Product[]>([]);

  const stepContext = useStepContext();
  const productContext = useProductContext();

  const handleProductAnchor = () => {
    ReactGA.event({
      category: "resultpage",
      action: "click",
      label: "website_redirection",
    });
    window.open(
      "https://se.com/us/en/product/" + productContext.product.sku,
      "_blank"
    );
  };

  const handleSubmit = (event: React.MouseEvent) => {
    const input =
      event.currentTarget.parentElement?.previousElementSibling?.querySelector(
        "input"
      );
    let pid: string = input?.value || "";
    if (pid === "") {
      setAdditionalErrorMessage("SKU number cannot be empty.");
      return;
    }
    setDataMapProd([]);
    setDataMapSku([]);
    setAdditionalErrorMessage("");
    setLoading();
    setTimeout(() => {
      fetchProduct(pid).then((product: Product) => {
        if (!product) {
          ReactGA.event({
            category: "resultpage",
            action: "unsuccesfulupload",
            label: pid,
          });
          if (stepContext.isSuccess) {
            stepContext.handleError();
          }
          if (pid === "test") {
            pid = "QO120GFI";
          }
          if (pid === "test2") {
            pid = "QOC16UF";
          }
          if (pid === "test3") {
            pid = "QO120";
          }
          fetchSimilarProductSkus(pid).then((similarSkuList: string[]) => {
            setDataMapProd([]);
            setDataMapSku(similarSkuList);
            stepContext.handleError();
            stepContext.displayStepTwo();
            setButtonLoading(false);
            setButtonDisplay(true);
            setLoadingButtonDisplay(false);
            setAdditionalErrorMessage(
              "The SKU number provided did not return any results."
            );
          });
        }

        if (product) {
          productContext.setProduct(product);
          setDataMapProd([product]);
          stepContext.handleSuccess();
          fetchProductPrice(pid).then((price: string) => {
            productContext.setProductPrice(price);
            unsetLoading();
            setButtonDisplay(true);
          });
        }
      });
    }, 2000);
  };

  const handleGoBack = () => {
    setDataMapProd([]);
    setDataMapSku([]);
    unsetLoading();
    productContext.setProductPhotoInvalid(false);
    stepContext.displayStepOne();
    stepContext?.handleSuccess();
    ReactGA.event({
      category: "resultpage",
      action: "click",
      label: "try_again",
    });
  };

  const unsetLoading = () => {
    setButtonLoading(false);
    setLoadingButtonDisplay(false);
    setButtonDisplay(true);
  };

  const setLoading = () => {
    setButtonLoading(true);
    setLoadingButtonDisplay(true);
    setButtonDisplay(false);
  };

  const FinalStepContextValues = {
    buttonLoading,
    setButtonLoading,
    buttonDisplay,
    setButtonDisplay,
    additionalErrorMessage,
    setAdditionalErrorMessage,
    loadingButtonDisplay,
    setLoadingButtonDisplay,

    DataMapSku,
    setDataMapSku,
    DataMapProd,
    setDataMapProd,

    handleProductAnchor,
    handleSubmit,
    handleGoBack,
    unsetLoading,
    setLoading,
  };

  return (
    <FinalStepContext.Provider value={FinalStepContextValues}>
      {children}
    </FinalStepContext.Provider>
  );
};

export const useFinalStepContext = () => {
  const finalStepContext = React.useContext(FinalStepContext);

  if (!finalStepContext)
    throw new Error("You need to use this hook inside a context provider");

  return finalStepContext;
};
