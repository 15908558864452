import { ChangeEvent } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button, Container, IconButton, TextField } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useAdminContext } from "../../../../../context/AdminContext";
import { processCsv, templateUrl } from "../../../../../helpers/fetchMapping";

const SignedInSection = () => {
  const adminContext = useAdminContext();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    adminContext.setLoading(true);
    setTimeout(() => {
      if (!event.target.files || event.target.files.length === 0) {
        console.error("Select a file");
        return;
      }
      const file = event.target;
      let authObj = {
        username: adminContext.username || "",
        password: adminContext.password || "",
      };
      processCsv(authObj, file).then((data: any) => {
        adminContext.setLoading(false);
        adminContext.setMessage(data.message);
        if (!data) {
          adminContext.setMessage("Invalid CSV file!");
          return;
        }
      });
    }, 1500);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const urlString = data.get("url-input-field")?.toString() || "";
    adminContext.setLoading(true);
    setTimeout(() => {
      event.preventDefault();
      let authObj = {
        username: adminContext.username || "",
        password: adminContext.password || "",
      };
      templateUrl(authObj, urlString).then((data: any) => {
        adminContext.setLoading(false);
        if (!data) {
          adminContext.setMessage("URL must include {PRODUCT_ID}!");
          return;
        }
        adminContext.setMessage(data.message);
      });
    }, 1500);
  };

  const GetCardContent = () => {
    if (adminContext.loading) {
      return (
        <LoadingButton
          disabled={true}
          className={"loading-btn"}
          loading={true}
        ></LoadingButton>
      );
    }

    return (
      <CardContent className={"card-content"}>
        <Typography variant={"h5"} className={"desc"} gutterBottom>
          Tap the button below and upload a mapping file
        </Typography>
        <CardActions className="card-actions">
          {adminContext.message !== "" && (
            <Typography className="message" variant="h5" sx={{ m: "0 auto" }}>
              {adminContext.message}
            </Typography>
          )}
          <Button
            endIcon={<FileUploadIcon />}
            className={"submit-btn"}
            variant={"contained"}
            size="medium"
            component={"label"}
          >
            <input
              hidden
              accept=".csv"
              type="file"
              onChange={(e) => handleFileUpload(e)}
            />
            Upload new mapping file
          </Button>
          <Container
            component="form"
            onSubmit={handleSubmit}
            className={"manual-input-form"}
          >
            <Typography className="search-input-title" variant="caption">
              Modify existing template URL?
            </Typography>
            <Box className={"input-div"}>
              <TextField
                id="url-input-field"
                name="url-input-field"
                label="New URL"
                variant="outlined"
                size="medium"
                className="input-field"
              />
              <Box className={"form-content"}>
                <IconButton
                  size="medium"
                  type={"submit"}
                  className="search-btn"
                >
                  <UpgradeIcon />
                </IconButton>
              </Box>
            </Box>
          </Container>
        </CardActions>
        <p
          className={"csv-availability-msg"}
          style={{
            fontWeight: "300",
          }}
        >
          Click{" "}
          <a
            href={"./assets/files/formatting_example.csv"}
            style={{
              color: "#fff",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to see an example of mapping file
        </p>
      </CardContent>
    );
  };

  return (
    <>
      <Box className="title-div">
        <Typography variant="h5" color="text.primary">
          New distributor setup
        </Typography>
        <Typography variant="h6" color="text.primary">
          Choose a specific name to create a new mapping for your clients
        </Typography>
      </Box>
      <Card className={"card"}>
        <GetCardContent />
      </Card>
    </>
  );
};
export default SignedInSection;
