import Avatar from "@mui/material/Avatar";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useAdminContext } from "../../../../context/AdminContext";
import SignInForm from "./components/SignInForm";
import SignUpForm from "./components/SignUpForm";

const LoginPanel = () => {
  const adminContext = useAdminContext();

  return (
    <Box className={"panel"}>
      <Box className={"login-panel"}>
        <MuiLink color="inherit" target={"_blank"} href="https://www.se.com/">
          <img
            className="se-logo"
            width={150}
            height={60}
            src={"./assets/images/se_g_logo.png"}
            alt={"Schneider electric logo"}
          />
        </MuiLink>
        <Avatar
          sx={{
            width: "50px",
            height: "50px",
            m: 1,
            border: "1px solid #a0a0a4",
          }}
        >
          <Box
            className={"se-logo-login-div"}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <img
              className="se-logo"
              src={"./assets/images/se-login-logo.png"}
              alt={"Schneider electric logo"}
              width={"100%"}
              height={"100%"}
            />
          </Box>
        </Avatar>
        <Box className={"form-div"}>
          {adminContext.selectedForm ? <SignInForm /> : <SignUpForm />}
        </Box>
      </Box>
    </Box>
  );
};
export default LoginPanel;
