import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const Copyright = () => {
  return (
    <Box className="copyright">
      <img src="./assets/images/nu_logo.png" className="nu-logo" alt="nu-img" />
      <Typography variant="caption" className="cpr-text" align="center">
        Copyright © Schneider Electric {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
};

export default Copyright;
